<template>
  <!--  <el-form-item :label-width="field.label && field.label.labelWidth ? field.label.labelWidth + 'px' : ''"-->
  <!--                :size="field.meta && field.meta.size ? field.meta.size : ''"-->
  <!--                :class="[field.label && field.label.labelPosition ? 'el-form&#45;&#45;labelPosition-' + field.label.labelPosition : 'el-form&#45;&#45;labelPosition-top']">-->
  <!--    <template slot="label">-->
  <!--      <Tooltip v-if="field.label && field.label.labelTooltip"-->
  <!--               :labelTooltip="field.label.labelTooltip"-->
  <!--               :content="field.label && field.label.name ? $t(field.label.name) : ''"></Tooltip>-->
  <!--      <span v-else class="labelWrap">{{field.label && field.label.name ? $t(field.label.name) : ''}}</span>-->
  <!--    </template>-->
  <!--    <div v-if="typeof value !== 'undefined'"-->
  <!--         class="el-form-text">-->
  <!--      <el-switch v-model="value" :disabled="meta && meta.disabled ?  meta.disabled : true"></el-switch>-->
  <!--    </div>-->
  <!--    <div v-else-->
  <!--         class="el-form-text">-->
  <!--      <span class="no-date-title">{{ $t('system.no_data') }}</span>-->
  <!--    </div>-->
  <!--  </el-form-item>-->

  <base-form-item :field="field">
    <div
        class="el-form-text"
        v-if="typeof value !== 'undefined'"
    >
      <el-switch
          :disabled="meta && meta.disabled ?  meta.disabled : true"
          v-model="value"
      ></el-switch>
    </div>
    <div
        class="el-form-text"
        v-else
    >
      <span class="no-date-title">{{ $t('system.no_data') }}</span>
    </div>
  </base-form-item>
</template>

<script>
import abstractForm from '../../mixin/index';
import BaseFormItem from '../../UI/FormItem.vue';

export default {
  name: 'index',
  mixins: [abstractForm],
  components: {BaseFormItem},
  beforeMount() {
    if (!this.value) {
      this.value = false;
    }
  },
};
</script>

<style scoped>

</style>
